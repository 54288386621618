import styled from "styled-components";

export const SearchBox = styled.div`
  position: relative;
  background: white;
  width: 100%;
  padding: 0.5rem 0.5rem;
  box-sizing: border-box;
  outline: none;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  color: #757575;
`;

export const SearchInput = styled.input`
  border: none;
  width: 100%;
  padding-right: 4rem;
  box-sizing: border-box;
  outline: none;
`;

export const PostsCount = styled.div`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
`;
