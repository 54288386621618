import styled from "styled-components";

export const PostTags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.small`
  background: var(--border-color);
  color: rgb(34, 36, 38);
  padding: 0.25rem 0.5rem;
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  border-radius: 3px;
`;
