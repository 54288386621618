import styled from "styled-components";
import { Link } from "gatsby";

export const PostsList = styled.div`
  margin-bottom: 3rem;
`;

export const Heading = styled.h2`
  border-top: 1px solid var(--border-color);
  padding-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span {
    margin-right: 1rem;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export const SeeAllButton = styled(Link)`
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  font-size: 50%;
  color: var(--bg-color);
  background: var(--link-color);

  &:hover {
    color: var(--bg-color);
    background: var(--link-hover-color);
  }
`;
