import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { FiPenTool } from "react-icons/fi";

import PostItemCard from "../PostItemCard";
import * as S from "./styles";

const PostsList = ({ posts, title = "", seeMore = false }) => (
  <S.PostsList>
    {title && (
      <S.Heading>
        <FiPenTool />
        <span>{title}</span>
        {seeMore && <S.SeeAllButton to="/posts">View all</S.SeeAllButton>}
      </S.Heading>
    )}
    {posts.map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug;
      return (
        <PostItemCard key={node.fields.slug} postInfo={node} title={title} />
      );
    })}
    {seeMore && <Link to="/posts">View all posts →</Link>}
  </S.PostsList>
);

PostsList.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        excerpt: PropTypes.string.isRequired,
        fields: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }).isRequired,
        frontmatter: PropTypes.shape({
          date: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          tags: PropTypes.arrayOf(PropTypes.string).isRequired,
          title: PropTypes.string.isRequired,
        }).isRequired,
        timeToRead: PropTypes.number.isRequired,
      }).isRequired,
    })
  ).isRequired,
  title: PropTypes.string,
  seeMore: PropTypes.bool,
};

export default PostsList;
