import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import PostTags from "../PostTags";
import * as S from "./styles";

const PostItemCard = ({ postInfo, title }) => (
  <Link to={postInfo.fields.slug} aria-label={title}>
    <S.PostItemCard>
      <header>
        <S.PostTitle>{title}</S.PostTitle>
        <small>
          {postInfo.frontmatter.date} • {postInfo.timeToRead} min. read
        </small>
      </header>
      <S.PostDescription>
        <small
          dangerouslySetInnerHTML={{
            __html: postInfo.frontmatter.description || postInfo.excerpt,
          }}
        />
      </S.PostDescription>
      <PostTags tags={postInfo.frontmatter.tags} />
    </S.PostItemCard>
  </Link>
);

PostItemCard.propTypes = {
  postInfo: PropTypes.shape({
    excerpt: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
    frontmatter: PropTypes.shape({
      date: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    timeToRead: PropTypes.number.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default PostItemCard;
