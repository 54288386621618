import React from "react";
import PropTypes from "prop-types";

import * as S from "./styles";

const PostTags = ({ tags }) => (
  <S.PostTags>
    {tags.map(tag => (
      <S.Tag key={tag}>{tag}</S.Tag>
    ))}
  </S.PostTags>
);

PostTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PostTags;
