import React from "react";
import PropTypes from "prop-types";

import * as S from "./styles";

const SearchPosts = ({ value, handleInputChange, postCount }) => (
  <S.SearchBox>
    <S.SearchInput
      type="text"
      aria-label="Search"
      placeholder="Search posts"
      onChange={handleInputChange}
      value={value}
    />
    <S.PostsCount>{postCount}</S.PostsCount>
  </S.SearchBox>
);

SearchPosts.propTypes = {
  value: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  postCount: PropTypes.number.isRequired,
};

export default SearchPosts;
