import styled from "styled-components";

export const PostItemCard = styled.article`
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 1rem;
  margin: 2rem 0;
  transition: 0.3s;

  &:hover {
    transform: scale(1.03);
  }
`;

export const PostTitle = styled.h3`
  margin-bottom: 0.25rem;
`;

export const PostDescription = styled.section`
  margin: 1rem 0;
`;
