import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PostsList from "../components/PostsList";
import SearchPosts from "../components/SearchPosts";

const SNoResults = styled.p`
  font-weight: bold;
  padding-top: 1rem;
`;

const NoResults = () => (
  <SNoResults>
    No results{" "}
    <span role="img" aria-label="sad">
      😢
    </span>{" "}
    Try a different keyword!
  </SNoResults>
);

const Posts = ({ data, location }) => {
  const [state, setState] = useState({
    filteredData: [],
    query: "",
  });
  const allPosts = data.allMarkdownRemark.edges;

  const handleInputChange = event => {
    const query = event.target.value;
    const filteredData = allPosts.filter(post => {
      const { description, title, tags } = post.node.frontmatter;
      return (
        description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase()) ||
        (tags && tags.join("").toLowerCase().includes(query.toLowerCase()))
      );
    });
    setState({
      query,
      filteredData,
    });
  };

  const posts = state.query ? state.filteredData : allPosts;

  return (
    <Layout pathname={location.pathname}>
      <SEO title="Posts" />
      <h1>Posts</h1>
      <SearchPosts
        value={state.query}
        handleInputChange={handleInputChange}
        postCount={posts.length}
      />
      {state.query && !posts.length ? (
        <NoResults />
      ) : (
        <PostsList posts={posts} />
      )}
    </Layout>
  );
};

Posts.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string.isRequired,
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }).isRequired,
            frontmatter: PropTypes.shape({
              date: PropTypes.string.isRequired,
              description: PropTypes.string.isRequired,
              tags: PropTypes.arrayOf(PropTypes.string).isRequired,
              title: PropTypes.string.isRequired,
            }).isRequired,
            timeToRead: PropTypes.number.isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Posts;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`;
